import React from "react"
import PropTypes from "prop-types"

const CenterImage = ({ children }) => (
  <div style={{ textAlign: "center" }}>{children}</div>
)

CenterImage.props = {
  children: PropTypes.node.isRequired,
}

export default CenterImage
