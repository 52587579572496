import React from "react"
import PropTypes from "prop-types"

const Credits = ({ children }) => (
  <p
    style={{
      fontStyle: "italic",
      display: "block",
      fontSize: "10px",
      textAlign: "center",
    }}
  >
    {children}
  </p>
)

Credits.props = {
  text: PropTypes.string.isRequired,
}

export default Credits
